//import './components/cookie';
import "./components/acardeon";
import "./components/dropmenu";
import "./components/modals";
import "./components/burger";
import "./components/cartconttent";
import "./components/headerScroll";
import "./components/mainSlider";
import "./components/stepper";
import "./components/moreCategory";
