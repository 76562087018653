var more = document.querySelectorAll(".catalog-varible--all");

for (var i = 0; i < more.length; i++) {
  more[i].addEventListener("click", function () {
    var showPerClick = 6;

    var hidden = this.parentNode.querySelectorAll("div.hiddencat");
    for (var i = 0; i < showPerClick; i++) {
      if (!hidden[i]) return (this.outerHTML = "");

      hidden[i].classList.add("box");
      hidden[i].classList.remove("hiddencat");
    }
  });
}
