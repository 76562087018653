const burger = document.querySelector(".burger");
const menu = document.querySelector(".header__nav");
const menuLinks = document.querySelectorAll("a");

burger.addEventListener("click", () => {
  menu.classList.toggle("expanded");
  burger.classList.toggle("expanded");
});

menuLinks.forEach((link) =>
  link.addEventListener("click", () => {
    menu.classList.remove("expanded");
    burger.classList.remove("expanded");
  })
);
