import Swiper, { Navigation, Scrollbar, Pagination } from "swiper";

Swiper.use([Navigation, Scrollbar, Pagination]);

const swiper = new Swiper(".slider__list", {
  slidesPerView: 1,
  loop: false,
  spaceBetween: 120,
  navigation: {
    nextEl: ".custom-next",
    prevEl: ".custom-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});
